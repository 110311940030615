#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}

.ant-upload.ant-upload-drag {
    height: auto !important;
}

.ant-statistic-title {
    color: #f1f1f1 !important;
    font-weight: bold;
    text-transform: capitalize;
}

.propCard > :first-child {
    padding: 12px;
}