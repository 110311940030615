.otp:hover{
    cursor: pointer;
}
.cont{
    display: flex;
    flex-direction: column;
    width:20%;
    align-items: center;
    justify-content: center;
}
.orderHeading{
    font-weight: bold;
    text-align: center;
    width:100%;
    border:0px solid lightgray;
    border-Right-width: 2px;

}
.userContainer{
    display: flex;
    flex-direction: column;
    width:33%;
    align-items: center;
    justify-content: center;
}
.heading{
    font-size: 16;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    width:20%;
    align-items: center;
    justify-content: center;
    border:0px solid lightgray;
    border-Right-width: 2px;
}
.userHeadings{
    font-size: 16;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: center;
    justify-content: center;
    border:0px solid lightgray;
    border-Right-width: 2px;
}
.loading {
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgb(169 166 166 / 80%);
}
.editStatus{
    margin-left: 10px;
}
/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

svg.tea {
    --secondary: black;
    position: sticky;
    top: 50%;
    left: 50%;
}

svg.tea #teabag {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: swing 2s infinite;
}

svg.tea #steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: steamLarge 2s infinite;
}

svg.tea #steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: steamSmall 2s infinite;
}

@-moz-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-webkit-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-o-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-moz-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-webkit-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-o-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }

    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-moz-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-webkit-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-o-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }

    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }

    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}